import PropTypes from 'prop-types'
import Api from '../Api'
import numeral from 'numeral'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles({
    nama: {
        height: '3.5rem',
    },
    foto: {
        height: 170,
        overflow: 'hidden'
    }
})

const RenderStok = (prop)=>{
    let b = prop.barang
    if(Api.isLogin()) {
        if(b.stok < 10 ) {
            return (
                <span className='text-merah2'>Ready <span className='text-hijau fa fa-check'/></span>
            )
        }
        if(b.stok < 20) {
            return <span className='text-orange'>Ready <span className='text-hijau fa fa-check'/></span>
        }
        return <span className='text-hijau'>Ready <span className='text-hijau fa fa-check'/></span>
    } else {
        if(b.stok > 0) {
            return <span className='text-hijau'>Ready <span className='text-hijau fa fa-check'/></span>
        } else {
            return <span className='text-merah2'>Kosong</span>
        }
    }
}

const ItemBarang = (props) => {
    const classes = useStyle()
    let b = props.barang
    return (
        <div className='mx-auto border rounded-md text-sm h-full' style={{width: 170}}>
            <a href={'/detail-barang/' + b.id} target="_blank">
                <div className={clsx(classes.foto)}>
                    <img src={(Api.baseUrlThumb1 + b.fotoid)} className='w-full mx-auto' alt='foto barang'/>
                </div>
                <div className='p-1'>
                    <div className={clsx(classes.nama)}>
                        {b.nama}
                    </div>
                    <div className='harga text-blue-900'>
                        {
                            'Rp ' + numeral(b.harga).format('0,0')
                        } / {b.satuan}
                    </div>
                    <div className='stok'>
                        Stok: <RenderStok barang={b}/>
                    </div>
                </div>
            </a>
        </div>
    )
}

ItemBarang.propTypes = {
    barang: PropTypes.object.isRequired
}
export default ItemBarang