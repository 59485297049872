import axios from 'axios'
import logoLombok from './logo-lombok.png'
import logoBali from './logo-bali.png'
import logoSurabaya from './logo-surabaya.png'
import sideImageBali from './aset/sidebar-bali.jpg'
import sideImageLombok from './aset/sidebar-lombok.jpg'
import sideImageSurabaya from './aset/sidebar-surabaya.jpg'
class Api {
    // static baseUrl = 'http://localhost:3000/shop/'
    // static baseUrl = 'https://amigoo.sawopos.com/shop/'
    
    static defaultCabang = "Lombok"
    // static defaultCabang = "Bali"
    // static defaultCabang = "Surabaya"
    // static websiteUrl = 'https://' + Api.defaultCabang.toLowerCase() + '.amigoo.id/'    
    static websiteUrl = 'https://kenshu.amigoo.id/'    
    
    static baseUrlFoto = Api.getBaseUrl() + 'foto/'
    static baseUrlThumb1 = Api.getBaseUrl() + 'thumb1/'
    static baseUrlThumb2 = Api.getBaseUrl() + 'thumb2/'

    static getShowHargaGrosir() {
        return (this.defaultCabang == "Surabaya")
    }

    static getGudangId() {
        return parseInt(localStorage.getItem("gudangId") || -1)
    }

    static setGudangId(gudangId) {
        localStorage.setItem("gudangId", gudangId+"")
    }

    static getBaseUrl() {
        let c = Api.defaultCabang
        if(c === 'Lombok') {
            // return 'http://localhost:3000/shop/'
            return 'https://kenshu.sawopos.com/shop/'
        } else if(c === 'Bali') {
            return 'https://amigoobali.sawopos.com/shop/'
        } else if(c === 'Surabaya') {
            return 'https://amigoosurabaya.sawopos.com/shop/'
        }
    }
    static getLogo() {
        let c = Api.defaultCabang
        if(c === 'Lombok') {
            return logoLombok
        } else if(c === 'Bali') {
            return logoBali
        } else if(c === 'Surabaya') {
            return logoSurabaya
        }
    }

    static getSideImage() {
        let c = Api.defaultCabang
        if(c === 'Lombok') {
            return sideImageLombok
        } else if(c === 'Bali') {
            return sideImageBali
        } else if(c === 'Surabaya') {
            return sideImageSurabaya
        }
    }

    static async login(user, password) {
        let api
        try {
            let res = await axios.post(Api.getBaseUrl() + 'login2', {
                user,
                password
            })
            api = res.data
        } catch (er) {
            console.error(er)
        }
        return api
    }

    static logout() {
        localStorage.setItem("token", "")
    }

    static setToken(token, user) {
        localStorage.setItem("token", token)
        localStorage.setItem("user", user)
    }

    static getUser() {
        return localStorage.getItem("user") || ""
    }

    static isLogin() {
        if(Api.getShowHargaGrosir()) {
            return true
        }
        let token = localStorage.getItem("token") || ""
        return token.length > 0
    }

    static async getHomeData(gudangId) {
        let api
        try {
            let res = await axios.post(Api.getBaseUrl() + 'home2', {
                gudangid: gudangId
            })
            api = res.data
        } catch (er) {
            console.error(er)
        }
        return api
    }

    static async getDaftarBarang(cari, kategoriId, brandId, skip, take, sort = 'created desc', gudangId = -1) {
        let api = {
            ok: false,
            msg: ''
        }
        let res = await axios.post(Api.getBaseUrl() + 'caribarang', {cari, kategoriId, brandId, skip, take, sort, gudangid: gudangId})
        api = res.data
        return api
    }

    static async getDaftarKategori() {
        let api = {
            ok: false,
            msg: ''
        }
        let res = await axios.post(Api.getBaseUrl() + 'kategori')
        api = res.data
        return api
    }

    static async getDaftarFooter() {
        let api = {
            ok: false,
            msg: ''
        }
        let res = await axios.post(Api.getBaseUrl() + 'footers')
        api = res.data
        console.dir(api)
        return api
    }

    static async getBarang(id) {
        let api = {
            ok: false,
            msg: ''
        }
        let res = await axios.post(Api.getBaseUrl() + 'detailbarang/' + id)
        api = res.data
        return api
    }

    static async getDaftarGudang() {
        let ret = []
        let res = await axios.post(Api.getBaseUrl() + 'daftargudang')
        ret = res.data
        return ret
    }
}

export default Api